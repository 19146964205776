<template>
  <div>
    <div class="flex">
      <div class="w-1/3 text-center">
        <Text :content="experience.name" customClass="text-sm md:text-lg" />
        <Text :content="experience.value" customClass="text-sm md:text-lg" weight="bold" />
      </div>
      <div class="w-1/3 text-center">
        <Text :content="magnitude.name" customClass="text-sm md:text-lg" />
        <Text :content="magnitude.percent"
          :customClass="`text-sm md:text-lg bg-${graphColor}-300 px-1 w-14 mx-auto border-4 border-${graphColor}-400`"
          weight="bold" />
      </div>
      <div class="w-1/3 text-center">
        <Text :content="desire.name" customClass="text-sm md:text-lg" />
        <Text :content="desire.value" customClass="text-sm md:text-lg" weight="bold" />
      </div>
    </div>
    <div class="relative flex w-full h-8 mt-2 mb-12 border-2 ">
      <div class="relative flex justify-end w-1/2">
        <div v-if="experience.value < 0"
          :class="`h-full bg-${graphColor}-300 opacity-60 border-2 border-${graphColor}-400 absolute right-0`"
          :style="`width:${Math.abs(experience.value) * 20}%`" />
        <div v-if="desire.value < 0"
          :class="`h-full bg-${graphColor}-300 opacity-60 border-2 border-${graphColor}-400 absolute right-0`"
          :style="`width:${Math.abs(desire.value) * 20}%`" />
      </div>
      <div class="relative flex justify-start w-1/2">
        <div v-if="experience.value > 0"
          :class="`h-full bg-${graphColor}-300 opacity-60 border-2 border-${graphColor}-400 absolute left-0`"
          :style="`width:${Math.abs(experience.value) * 20}%`" />
        <div v-if="desire.value > 0"
          :class="`h-full bg-${graphColor}-300 opacity-60 border-2 border-${graphColor}-400 absolute left-0`"
          :style="`width:${Math.abs(desire.value) * 20}%`" />
      </div>
    </div>
    <div class="flex justify-around">
      <div class="w-1/3 text-center">
        <Text :content="outOfMarket.name" customClass="text-sm md:text-lg" />
        <Text :content="outOfMarket.percent + '%'" customClass="text-sm md:text-lg" weight="bold" />
      </div>
      <div class="w-1/3 text-center">
        <Text :content="inMarket.name" customClass="text-sm md:text-lg" />
        <Text :content="inMarket.percent + '%'" customClass="text-sm md:text-lg" weight="bold" />
      </div>
      <div class="w-1/3 text-center">
        <Text :content="earlyAdopter.name" customClass="text-sm md:text-lg" />
        <Text :content="earlyAdopter.percent + '%'" customClass="text-sm md:text-lg" weight="bold" />
      </div>
    </div>
  </div>
</template>

<script>
import Text from '../../atoms/Text/Text.vue'
/**
 * - Use it when show tabs with underline and icons
 * - tabs is required. default is empty array
 */
export default {
  components: {
    Text,
  },
  props: {
    experience: {
      type: Object,
      default: () => { }
    },
    magnitude: {
      type: Object,
      default: () => { }
    },
    desire: {
      type: Object,
      default: () => { }
    },
    outOfMarket: {
      type: Object,
      default: () => { }
    },
    inMarket: {
      type: Object,
      default: () => { }
    },
    earlyAdopter: {
      type: Object,
      default: () => { }
    },
  },
  mounted() {
    if (parseInt(this.magnitude.percent) < 25) {
      this.graphColor = "orange";
    } else if (parseInt(this.magnitude.percent) < 49) {
      this.graphColor = "yellow";
    } else if (parseInt(this.magnitude.percent) < 101) {
      this.graphColor = "green";
    }
  },
  data() {
    return {
      graphColor: ""
    }
  }
};
</script>
