<template>
    <div class="w-full">
        <div @mouseover="isShow = true" @mouseleave="isShow = false" class="w-full">
            <slot v-if="leading" />
            {{ content }}
        </div>
        <div class="relative">
            <div v-if="isShow" :class="`absolute ${isRight ? 'right-0' : 'left-0'} z-20 max-w-xs p-3 mt-2 bg-gray-600 rounded-lg shadow-sm min-w-200`">
                <div :class="`absolute z-0 w-4 h-4 -mt-4 transform rotate-45 bg-gray-600 ${isRight ? 'right-4' : 'left-4'}`" />
                <Text customClass="z-10" :content="tooltip" size="sm" weight="medium" color="white" />
            </div>
        </div>
    </div>
</template>

<script>
    import Text from "./../Text/Text.vue";
    export default {
        name: "Tooltip",
        components: {
            Text
        },
        props: {
            content: {
                type: String,
                default: ""
            },
            tooltip: {
                type: Array,
                default: () => []
            },
            leading: {
                type: Boolean,
                default: false
            },
            isRight: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isShow: false
            }
        },
    }
</script>