<template>
  <SideOver v-if="showSwiper" @onClickEnter="onClickEnter" isLarge :title="title" :is-show="isShow" isMultiple
    @onClose="() => onClose()" :label="content" :isLoading="isLoading" :buttons="getButtons()">
    <swiper v-if="showSwiper" :controller="{ control: swiper }" :simulateTouch="false" :modules="[Virtual, Controller]"
      @swiper="setSwiper" :slides-per-view="1" :space-between="10" :initialSlide="activeIndex && parseInt(activeIndex)">
      <swiper-slide class="relative" v-for="(item, index) in formList" :key="item.id">
        <div class="flex flex-1 flex-col p-6 space-y-4 h-full overflow-y-auto">
          <SurveyForm :id="item.id" :ref="(el) => assignRef(el, index)" :list="item.list" :title="item.name"
            :index="activeIndex"
            :description="item.description" class="w-full" titleTextSize="lg" titleTextColor="black"
            containerSpacing="space-y-3" bodySpacing="space-y-3" fieldSpacingClass="pt-4" :showMandatoryLabel="false"
            :modelKey="modelKey" :orig-form-data="savedFormData" @on-validate="onValidate" :formData="finalData" />
        </div>
      </swiper-slide>
      <swiper-slide v-if="get(finalData, 'name')">
        <div class="flex-1 flex-col p-6 h-full overflow-y-auto">
          <div class="space-y-3">
            <Text size="md" color="black" content="Risks and Assumptions" />
            <div>
              <Text size="sm" weight="bold" color="black" content="Opportunity" />
              <Text size="sm" color="black"
                v-html="`Is the biggest problem you can solve for<span class=' px-1 rounded font-bold'>${get(finalData, 'targetCustomer.name') || ''}</span>related to how they<span class=' px-1 rounded font-bold'>${get(finalData, 'reasonSecond.label') || ''}?</span> Is there a bigger one?`" />
            </div>
            <Text size="sm" color="black"
              v-html="`Are<span class=' px-1 rounded font-bold'>${get(finalData, 'targetCustomer.name') || ''}</span>the group that is the most motivated to change how they<span class=' px-1 rounded font-bold'>${get(finalData, 'reasonSecond.label') || ''}?</span> Is there a more motivated group that might even have a bigger problem?`" />
            <div>
              <Text size="sm" weight="bold" color="black" content="Value" />
              <Text size="sm" color="black"
                v-html="`How much do<span class=' px-1 rounded font-bold'>${get(finalData, 'targetCustomer.name') || ''}</span>value making it significantly<span class=' px-1 rounded font-bold'>${getProblems()}</span>for them to<span class=' px-1 rounded font-bold'>${get(finalData, 'reasonSecond.label') || ''}?</span> Will they pay and how they do it now?`" />
            </div>
            <div>
              <Text size="sm" weight="bold" color="black" content="Delivery" />
              <Text size="sm" color="black"
                v-html="`Is<span class=' px-1 rounded font-bold'>${get(finalData, 'description')}</span>the most profitable way to make it significantly<span class=' px-1 rounded font-bold'>${getProblems()}</span>for<span class=' px-1 rounded font-bold'>${get(finalData, 'targetCustomer.name') || ''}</span>to<span class=' px-1 rounded font-bold'>${get(finalData, 'reasonSecond.label') || ''}?</span> Is there a better way?`" />
            </div>
          </div>
          <div class="bg-primary-700 px-6 py-4 w-full mt-6">
            <Text size="sm" weight="semibold" color="white" content="Great Job!" />
            <Text size="xs" weight="" color="white"
              content="If everything looks good, you're ready to start testing these assumptions and discovering the most viable opportunities out in the world. Let's go!"
              custom-class="mt-2" />
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </SideOver>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Input from "../../../molecules/Inputs/Component/Component.vue";
import Textarea from "../../../molecules/Textareas/Simple/Simple";
import Select from "../../../molecules/Selects/Component/Component.vue";
import Button from "../../../atoms/Button/Button.vue";
import SideOver from "../../SideOvers/WithBackgroundOverlay/WithBackgroundOverlay.vue";
import AutoComplete from "../../../molecules/AutoComplete/AutoComplete";
import MultiSelectDropdown from "../../../molecules/Dropdown/MultiSelectDropdown/MultiSelectDropdown";
import Simple from "../../../organisms/Tables/Simple/Simple.vue";
import { ref, watch, onMounted, nextTick } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import { Controller, Virtual } from "swiper";
import "swiper/swiper.min.css";
import SurveyForm from "../../SurveyForm/SurveyForm.vue";
import { upperFirst, get } from 'lodash'

export default {
  components: {
    Text,
    Icon,
    Input,
    Select,
    Button,
    SideOver,
    Textarea,
    Swiper,
    SwiperSlide,
    SurveyForm,
    AutoComplete,
    MultiSelectDropdown,
    Simple,
  },
  props: {
    isShow: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
    formList: {
      type: Array,
      default: () => [],
    },
    modelKey: {
      type: String,
      default: "id",
    },
    finalData: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      get,
    }
  },
  watch: {
    isShow: function (val) {
      if (val) {
        setTimeout(() => (this.showSwiper = true), 10);
      } else {
        setTimeout(() => (this.showSwiper = false), 700);
      }
    },
  },
  setup(props, { emit }) {
    const swiper = ref(null);
    const formRefs = ref([]);
    let surveyAnswersMap = {};
    let savedFormData = ref(props.finalData || {});
    const isValid = ref(false);
    const setSwiper = (e) => {
      swiper.value = e;
    };
    const activeIndex = ref(0);
    const showSwiper = ref(false);
    const show = ref(false);
    const onClose = () => {
      emit("onClose");
      setTimeout(() => (showSwiper.value = false), 500);
    };
    const onNext = (isSkip) => {
      surveyAnswersMap = formRefs.value[activeIndex.value].getFormObject();
      savedFormData = { ...savedFormData, ...surveyAnswersMap };
      if (isSkip) {
        swiper.value.slideTo(activeIndex.value + 2);
        activeIndex.value = activeIndex.value + 2;
      } else {
        swiper.value.slideTo(activeIndex.value + 1);
        activeIndex.value = activeIndex.value + 1;
      }
      if (!props.finalData?.name) {
        let valid = formRefs.value[activeIndex.value].checkValidForm();
        onValidate(valid);
      }
    };
    const onBack = () => {
      const types = ['BUSINESSES', 'ORGANIZATIONS', 'GOVERNMENTS'];
      const sTypes = ['INDIVIDUALS', 'EMPLOYEES'];
      if (activeIndex.value < formRefs.value?.length) {
        surveyAnswersMap = formRefs.value[activeIndex.value].getFormObject();
        savedFormData = { ...savedFormData, ...surveyAnswersMap };
      }

      if (activeIndex.value === 3 && !types?.find(type => type === props.finalData?.targetCustomer?.targetCustomerUserType?.name)) {
        swiper.value.slideTo(activeIndex.value - 2);
        activeIndex.value = activeIndex.value - 2;
      } else if (activeIndex.value === 4 && !sTypes?.find(type => type === props.finalData?.targetCustomer?.targetCustomerUserType?.name)) {
        swiper.value.slideTo(activeIndex.value - 2);
        activeIndex.value = activeIndex.value - 2;
      } else {
        swiper.value.slideTo(activeIndex.value - 1);
        activeIndex.value = activeIndex.value - 1;
      }

      let valid = formRefs.value[activeIndex.value].checkValidForm();
      onValidate(valid);
    };
    const activeBtnText = () => {
      if (props?.finalData?.name && activeIndex.value === props.formList.length) {
        return 'Save'
      } else if (activeIndex.value === props.formList.length - 1 && !props?.finalData?.name) {
        return 'Save'
      } else {
        return 'Next'
      }
    }
    const validateDemographic = () => {
      let formObject = formRefs.value[activeIndex.value].getFormObject();
      console.log(formObject, 'formObject');
      const items = ['age', 'gender', 'ethnicity', 'income', 'interest'];
      if (formObject?.location?.length > 0 && items?.find(item => formObject[item]?.length > 0)) {
        return false;
      } else {
        return true;
      }
    }
    const getButtons = () => {
      if (activeIndex.value === 0) {
        return [
          {
            label: "Next",
            type: "primary",
            isLoading: props.isSaving,
            isDisabled:
              formRefs.value[activeIndex.value]?.checkValidForm(),
            action: () => onSave(),
          },
        ];
      } else {
        return [
          {
            label: "Back",
            type: "secondary",
            isLoading: false,
            action: () => onBack(),
          },
          {
            label: activeBtnText(),
            type: "primary",
            isLoading: props.isSaving,
            isDisabled:
              activeIndex.value === 3 ? validateDemographic() : formRefs.value[activeIndex.value]?.checkValidForm(),
            action: () => onSave(),
          },
        ];
      }
    };
    const onSave = () => {
      if (activeIndex.value === 3 && props.formList[3]?.name === 'Demographics, Interests, and Activities') {
        let formObject = formRefs.value[activeIndex.value].getFormObject();
        console.log(formObject, 'formObject');
        const items = ['age', 'gender', 'ethnicity', 'income', 'interest'];
        if (formObject?.location?.length > 0 && items?.find(item => formObject[item]?.length > 0)) {
        } else {
          return false;
        }
      }
      if (activeIndex.value === 1 && props.formList[1]?.name === 'Target customer details') {
        let formObject = formRefs.value[activeIndex.value].getFormObject();
        const types = ['BUSINESSES', 'ORGANIZATIONS', 'GOVERNMENTS'];
        if (!types?.find(type => type === formObject?.customerType?.name)) {
          emit("saveForm", formObject, activeIndex.value);
          if (props.finalData?.name) {
            onNext(true)
          }
          return false;
        }
      }
      console.log(activeIndex.value, props.finalData?.targetCustomer?.targetCustomerUserType?.name)
      if (activeIndex.value === 2) {
        let formObject = formRefs.value[activeIndex.value].getFormObject();
        const types = ['INDIVIDUALS', 'EMPLOYEES'];
        if (!types?.find(type => type === props.finalData?.targetCustomer?.targetCustomerUserType?.name)) {
          emit("saveForm", formObject, activeIndex.value);
          if (props.finalData?.name) {
            onNext(true)
          }
          return false;
        }
      }
      if (activeIndex.value + 1 === props.formList.length) {
        let formObject = formRefs.value[activeIndex.value].getFormObject();
        emit("saveForm", formObject, activeIndex.value);
        if (props.finalData?.name) {
          onNext()
        }
      } else if (props?.finalData?.name && activeIndex.value === props.formList.length) {
        emit('saveForm', {}, activeIndex.value)
      } else {
        let formObject = formRefs.value[activeIndex.value].getFormObject();
        emit("saveForm", formObject, activeIndex.value);
        onNext();
      }
    };

    const assignRef = (el, index) => {
      if (index >= formRefs.value.length) {
        formRefs.value.push(el);
      } else {
        formRefs.value[index] = el;
      }
      return formRefs.value[index];
    };

    const onValidate = (val) => {
      isValid.value = !val;
    };

    const getProblems = () => {
      let text = ''
      props.finalData?.reasonThird?.map((problem, i) => {
        if (props.finalData?.reasonThird?.length > 1) {
          if (i === props.finalData?.reasonThird?.length - 1) {
            text += 'and ' + problem.improvement
          } else if (i === props.finalData?.reasonThird?.length - 2) {
            text += problem.improvement + ' '
          } else {
            text += problem.improvement + ', '
          }
        } else {
          text = problem.improvement;
        }
      })
      return text
    }
    onMounted(() => {
      setTimeout(() => {
        show.value = props.isShow;
      }, 50);
    });
    const onClickEnter = () => {
      if (props.formList[activeIndex.value]?.name === 'Existing Solution') return false;
      if (!formRefs.value?.[activeIndex.value]?.checkValidForm()) {
        onSave()
      }
    }
    return {
      show,
      isFocusFirst: true,
      isFocusSecond: true,
      isFocusThird: true,
      setSwiper,
      swiper,
      activeIndex,
      showSwiper,
      onClose,
      onNext,
      onBack,
      getButtons,
      onSave,
      Controller,
      Virtual,
      formRefs,
      assignRef,
      onValidate,
      savedFormData,
      upperFirst,
      getProblems,
      onClickEnter,
    };
  },
};
</script>
<style scoped>
.swiper {
  height: 100%;
}
</style>
<style>
.select-inline {
  display: flex;
  align-items: center;
}

.select-inline label {
  margin-bottom: 0 !important;
  margin-right: 20px;
  font-weight: 500 !important;
}

.select-inline div {
  flex: 1;
}
</style>
