<template>
  <div class="w-full flex flex-col overflow-x-hidden">
    <div class="w-full">
      <div class="mt-4">
        <NavigationGraph :experience="navigationGraph.experience" :magnitude="navigationGraph.magnitude"
          :desire="navigationGraph.desire" :outOfMarket="navigationGraph.outOfMarket" :inMarket="navigationGraph.inMarket"
          :earlyAdopter="navigationGraph.earlyAdopter" />
      </div>
      <div class="w-full mt-4" v-for="(demographic, index) in demographics" :key="index">
        <Demographic :cells="demographic.content" :title="demographic.title" />
      </div>
      <div class="mt-12 grid grid-cols-1 sm:grid-cols-1 gap-x-2">
        <!-- <div class="flex gap-4">
            <Table v-for="table in mdTables" :table="table" class="hidden w-1/2 lg:block" />
        </div> -->
        <Table :table="tables" class="block text-sm px-0 md:text-lg" from="opportunityDetail" />
      </div>
      <div class="bg-gray-200 rounded-md px-8 py-6 mt-4">
        <div class=" space-y-2">
          <Text :content="accordions.name" size="xl" weight="semibold" color="gray-900"></Text>
          <Text customClass="text-sm md:text-base" content="Innovation (improvement)" size="lg" weight="bold"
            color="gray-800" />
          <Text customClass="text-sm md:text-base"
            content="This is an opportunity that is open for INNOVATION, meaning this is something that is being done. but there is room to do it better. Your target customer is somewhat motivated to get this done, and there is some room for improvement."
            size="sm" color="gray-800" />
          <!-- <Text customClass="text-sm md:text-base"
            content="This is an opportunity that is open for INNOVATION, meaning this is something that is being done. but there is room to do it better. Your target customer is somewhat motivated to get this done, and there is some room for improvement."
            size="sm" color="gray-800" /> -->
        </div>
      </div>
    </div>
  </div>
  <div v-if="isOpen" class="fixed inset-0 bg-gray-400">
    <div class="w-full h-full block bg-white px-4 py-5 overflow-y-scroll">
      <a class="flex justify-end items-end" @click="isOpen = false">
        <Icon name="XIcon" size="7" color="gray-500" />
      </a>
      <div v-html="information"></div>
    </div>
  </div>
</template>

<script>
import Text from "../../atoms/Text/Text.vue";
import Icon from '../../atoms/Icons/Icons.vue'
import Button from '../../atoms/Button/Button.vue'
import Demographic from "../../molecules/Demographic/Demographic.vue";
import Table from "../Tables/Basic/Basic.vue";
import NavigationGraph from "../../molecules/NavigationGraph/NavigationGraph.vue";

export default {
  name: "OpportunityDataDetail",
  components: {
    Text,
    Demographic,
    Table,
    NavigationGraph,
    Icon,
    Button
  },
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    navigationGraph: {
      type: Object,
      default: () => {
      },
    },
    demographics: {
      type: Array,
      default: () => [],
    },
    accordions: {
      type: Object,
      default: () => {
      },
    },
    // leftAccordions: {
    //     type: Object,
    //     default: () => {},
    // },
    tables: {
      type: Array,
      default: () => [],
    },
    // mdTables: {
    //     type: Array,
    //     default: () => [],
    // },
    title: {
      type: String,
      default: ""
    },
    information: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: null
    }
  },
}
</script>
