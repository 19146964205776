<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed inset-0 overflow-hidden" @close="() => {open = false; $emit('onClose')}">
			<div class="absolute inset-0 overflow-hidden z-10">
				<TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100"
												 leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
					<DialogOverlay class="absolute inset-0 transition-opacity bg-gray-500 bg-opacity-75 z-20"/>
				</TransitionChild>

				<div class="fixed inset-y-0 right-0 flex max-w-full pointer-events-none z-30">
					<TransitionChild as="template"
						enter="transform transition ease-in-out duration-500 sm:duration-700"
						enter-from="translate-x-full" enter-to="translate-x-0"
						leave="transform transition ease-in-out duration-500 sm:duration-700"
						leave-from="translate-x-0" leave-to="translate-x-full">
						<div class="flex flex-col w-screen h-screen bg-white shadow-xl pointer-events-auto md:max-w-sm">
              <div class="flex-none px-4 py-6 bg-gray-100">
								<div class="flex items-start justify-between">
									<div class="flex flex-col">
                    <div class="my-2">
									    <Text :content="label" size="sm" color="gray-900" weight="normal" />
								    </div>
                    <div class="flex flex-wrap space-x-2">
                      <Text :content="dialogTitle" size="lg" weight="semibold" color="black" />
                      <Badge bgColor="white" isRounded textColor="gray-700" px="2" py="1" customClass="border border-gray-700" :label="badgeText"></Badge>
                    </div>
										
									</div>
									<div class="flex items-center ml-3 h-7">
										<button type="button"
														class="text-gray-400 rounded-md hover:text-gray-500 focus:outline-none "
                            @click="() => {open = false; $emit('onClose')}">
											<Icon name="XIcon" size="6" color="gray-500" class="hover:text-gray-300"/>
										</button>
									</div>
								</div>
							
							</div>
							<!-- Replace with your content -->
							<div class="flex-1 h-full w-full overflow-y-auto">
                <div class="flex flex-col">
                  <div class="flex flex-row py-2 px-4 items-center justify-center">
                    <Text element="span" :content="title" size="xl" customClass="whitespace-nowrap" weight="bold"
                        color="gray-700" />
                  </div>
                  <div class="w-full px-4">
                      <div class="mt-4">
                          <NavigationGraph :experience="navigationGraph.experience" :magnitude="navigationGraph.magnitude"
                              :desire="navigationGraph.desire" :outOfMarket="navigationGraph.outOfMarket"
                              :inMarket="navigationGraph.inMarket" :earlyAdopter="navigationGraph.earlyAdopter" />
                      </div>
                      <div class="w-full mt-4" v-for="(demographic,index) in demographics" :key="index">
                          <Demographic :cells="demographic.content" :title="demographic.title" />
                      </div>
                      <div class="mt-12">
                          <div class="flex flex-col space-y-2">
                            <template v-for="(table,index) in tables" :key="index">
                              <Table  :title="table.title" :table="table" class="block text-sm px-0" />
                            </template>
                          </div>
                          
                      </div>
                  </div>
                </div>
							</div>  
						</div>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Button from "../../../atoms/Button/Button.vue";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";
import Demographic from "../../../molecules/Demographic/Demographic.vue";
import Table from "../../Tables/Basic/Basic.vue";
import NavigationGraph from "../../../molecules/NavigationGraph/NavigationGraph.vue";
import OpportunityDetail from "../../OpportunityDataDetail/OpportunityDataDetail.vue";
/**
 * - Use it to show  panel with background overlay and opportunity detail
 */
export default {
  emits: ["onClose"],
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Text,
    Icon,
    Button,
    Badge,
    OpportunityDetail,
    Demographic,
    Table,
    NavigationGraph,
  },
  props: {
    /**
     * use it to show label
     */
    dialogTitle: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    badgeText: {
      type: String,
      default: null,
    },
    //opportunity detail props
    navigationGraph: {
      type: Object,
      default: () => {},
    },
    demographics: {
      type: Array,
      default: () => [],
    },
    tables: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    isShow: function (val) {
      this.open = ref(val);
    },
  },
  data() {
    const open = ref(this.isShow);

    return {
      open,
    };
  },
};
</script>

