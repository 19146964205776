<template>
    <Text :content="title" size="xl" weight="medium" />
    <div class="flex w-full mt-4">
        <div v-for="(cell, index) in cells" :key="cell.name" :style="`width: ${100*cell.count/totalCount}%`">
            <Tooltip :leading="true" :tooltip="getTooltip(cell)" :isRight="index > (cells.length-1)/2">
                <div :class="`bg-${cell.color}-300 border-2 border-${cell.color}-400 py-2`">
                    <Text :content="cell.name" align="center" color="gray-900"
                        customClass="text-xs md:text-lg whitespace-nowrap truncate" />
                    <Text :content="cell.count" align="center" color="gray-900"
                        customClass="text-xs md:text-lg whitespace-nowrap truncate" weight="bold" />
                </div>
            </Tooltip>
        </div>
    </div>
</template>

<script>
import Text from "../../atoms/Text/Text.vue";
import Tooltip from "../../atoms/Tooltip/Tooltip.vue";
import {isArray} from 'lodash'
export default {
    name: "Demographic",
    components: {
        Text,
        Tooltip
    },
    props: {
        title: {
            type: String,
            default: ""
        },
        cells: {
            type: Array,
            default: () => { }
        }
    },

    mounted() {
        this.cells.map(cell => {
            this.totalCount += cell.count;
        });
    },

    data() {
        return {
            totalCount: 0,
            isArray
        }
    },
    methods: {
        getTooltip(cell) {
            return isArray(cell.tooltip) ? cell.tooltip?.join(' '): cell?.tooltip
        }
    }
}
</script>