<template>
	<!-- <VueSlider v-model="val"></VueSlider> -->
	<PublicSurveyTemplate :stepsData="form" show-greeting-view show-success-view :gvTitle="greetingViewParams.gvTitle" modelKey="modelField"
	:gvDescription="greetingViewParams.gvDescription" :svTitle="successViewParams.svTitle" :svDescription="successViewParams.svDescription"
	:svLinkText="successViewParams.svLinkText" :svSubSectionText="successViewParams.svSubSectionText">
	</PublicSurveyTemplate> 
<!-- <OpportunityMapTemplate
		:nodes="opportunityMapData.nodes"
		:links="opportunityMapData.links"
		:nodeMapping="opportunityMapData.nodeMapping"
		:legend="opportunityMapData.legend"
		:title="opportunityMapData.title"
		:btnLabel="opportunityMapData.btnLabel"
		:btnIcon="opportunityMapData.btnIcon"
		:badgeText="opportunityMapData.badgeText" :detailDialogTitle="opportunityMapData.detailDialogTitle" :demographics="opportunityMapData.demographics"
		:navigationGraph="opportunityMapData.navigationGraph"  :dialogTitle="opportunityMapData.dialogTitle"
		:label="opportunityMapData.label" :tables="opportunityMapData.tables"
	></OpportunityMapTemplate> -->
	<!-- <div class="flex flex-col w-full h-screen">
		<Button content="open slider" @click="() => isShow = true"></Button>
		<WithSwiper
				v-bind="{ ...c.addIdea, isShow: isShow, isLoading: false, isSaving: isSaving, formList: form, modelKey: 'modelField'}"
				@saveForm="(formObject) => saveForm(formObject)" @onClose="() => { isShow = false }"></WithSwiper>
	</div> -->
</template>

<script>
import PublicSurveyTemplate from '../components/templates/PublicSurveyView/PublicSurveyView.vue';
import OpportunityMapTemplate from '../components/templates/OpportunityMapView/OpportunityMapView.vue'
import {surveyWithSteps, opportunity, opportunityLinks, form} from '../../.storybook/sampleData'
import {ref} from 'vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import WithSwiper from '../components/organisms/Modals/WithSwiper/WithSwiper.vue'
import {c} from '../components/constants.js';
import Button from '../components/atoms/Button/Button.vue'

export default {
	name: "Test",
	components: {
		PublicSurveyTemplate,
		OpportunityMapTemplate,
		VueSlider,
		WithSwiper,
		Button
	},
	setup() {
		const isShow = ref(false);
		const isSaving = ref(false);
		const stepsData = ref(surveyWithSteps);
		const val = ref([0, 50])
		const successViewParams = ref({
			showSuccessView: true,
			svTitle: 'Thanks so much for taking the time to take the survey!',
			svSubtitle: 'Please don\'t hesitate to email us with any questions or comments',
			svDescription: 'If you know any other adults in physical therapy who would be willing to share their thoughts, we\'d be grateful if you\'d pass this link along.',
			svSubSectionText: 'Got your own idea and want to do your own research like this? Get started free now with DSCVRY!',
			svButtonLabel: 'Join Now!',
			svLinkText: 'data.dscvry.com?survey=09a8sd0f9asdkjlas'
		});
		const greetingViewParams = ref({
			showGreetingView: true,
			gvTitle: 'Thanks so much for helping us learn about how you do your physical therapy exercises at home.',
			gvDescription: 'This will only take a couple of minutes so let\'s get started!',
		});
		const opportunityMapData = ref({
					nodes: opportunity,
					links: opportunityLinks,
					nodeMapping: {
						'feature': 'gray',
						'opportunity': 'blue',
						'solution': 'green'
					},
					legend: [
						{
							id: 1,
							type: 'solution',
							label: 'How'
						},
						{
							id: 2,
							type: 'opportunity',
							label: 'Why'
						}
					],
					title: '<span class="font-semibold text-gray-800">Adults in physical therapy <span class="font-medium text-gray-600">and how they manage their exercises at home</span></span>',
					btnLabel: 'View Table',
					btnIcon: 'ViewListIcon',
					dialogTitle: 'keep track what exercises to do',
					label: 'Adults in physical therapy need to..',
					badgeText: 'Ability',
					isShow: true,
					onClose: () => console.log('clicked'),
					detailDialogTitle: 'Survey Summary',
					navigationGraph: {
						experience: {
							name: 'Experience',
							percent: '60',
							value: '-1.9'
						},
						magnitude: {
							name: 'Magnitude',
							percent: '49.9'
						},
						desire: {
							name: 'Desire',
							percent: '80',
							value: '+2.5'
						},
						outOfMarket: {
							name: 'Out Of Market',
							percent: '35%'
						},
						inMarket: {
							name: 'In Market',
							percent: '65%'
						},
						earlyAdopter: {
							name: 'Early Adopter',
							percent: '12%'
						}
					},
					demographics: [
						{
							title: '',
							content: [
								{
									name: 'Out',
									count: 13,
									color: 'gray',
									tooltip: ['Out Motivation (1-24)', '13 people (30.3%)']
								},
								{
									name: 'Low',
									count: 13,
									color: 'orange',
									tooltip: ['Low Motivation (1-24)', '13 people (30.3%)']
								},
								{
									name: 'Mid',
									count: 7,
									color: 'yellow',
									tooltip: ['Mid Motivation (1-24)', '13 people (30.3%)']
								},
								{
									name: 'High',
									count: 3,
									color: 'green',
									tooltip: ['High Motivation (1-24)', '13 people (30.3%)']
								}
							]
						}
					],
					tables: [
						{
							title: 'Table title',
							header: [],
							body: [
								['Magnitude', '29.9'],
								['Desire', '+2.5'],
								['Experience', '-1.9'],
								['Activity', '95%'],
								['Frequency', '12x/week']
							]
							// More people...
						},
						{
							header: [],
							body: [
								['Out of Market', '35%'],
								['In Market', '65%'],
								['Adjusted Market', '18,000,000'],
								['Early Adopters', '12%'],
								['Early Adopter Size', '3,300,000']
							]
							// More people...
						}
					]
				}
		)
		const saveForm = (formObject) => {
			isSaving.value = true;
			console.log('save form data', formObject);
			isSaving.value = false;
		}
		return {
			greetingViewParams,
			successViewParams,
			stepsData,
			val,
			opportunityMapData,
			c,
			isShow,
			isSaving,
			saveForm,
			form
		}
	}
}
</script>

<style scoped>

</style>
