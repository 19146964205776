<template>
	<div>
		<div class="sm:flex sm:items-center">
			<div class="sm:flex-auto">
				<Text size="xl" weight="semibold" color="gray-900" :content="title" />
				<Text size="sm" color="gray-700" :content="description" class="mt-2" />
			</div>
		</div>
		<div class="px-3 sm:px-8 mt-0 sm:mt-4">
			<div v-if="table.body.length > 0" class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full px-1 pb-2 border-t align-middle">
					<div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
						<table class="min-w-full divide-y divide-gray-300">
							<thead class="bg-gray-50">
								<tr class="divide-x divide-gray-200">
									<th v-for="(item, id) in table.header" :key="id" scope="col"
										class="py-3.5 px-3 text-left text-md font-semibold text-gray-900 sm:pl-6">
										<Text :content="item" />
									</th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr v-for="(item, id) in table.body" :key="id" class="divide-x divide-gray-200">
									<td v-for="(i, index) in item" :key="index"
										:class="`${index !== 0 && 'font-semibold'} whitespace-nowrap w-1/2 py-4 pl-4 pr-3 text-md text-gray-900 sm:pl-6`">
										{{ getData(item, i) }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div v-else class="flex justify-center items-center w-full">
				<Empty v-bind="empty" />
			</div>

		</div>
	</div>
</template>

<script>
import Button from '../../../atoms/Button/Button.vue'
import Link from '../../../atoms/Link/Link.vue'
import Text from '../../../atoms/Text/Text.vue'
import Empty from '../../EmptyState/Simple/Simple.vue'
import numeral from 'numeral'
/**
 * - Use it to show a simple table with vertical lines
 */
export default {
	components: {
		Button, Link, Text, Empty
	},
	props: {
		/**
		 * Use it for table list
		 */
		table: {
			type: Object,
			default: () => { }
		},
		/**
		 * Use it for table title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Use it for table description
		 */
		description: {
			type: String,
			default: ''
		},
		empty: {
			type: Object,
			default: () => { }
		},
		from: {
			type: String,
			default: ''
		},
	},
	data() {
		return {
			numeral
		}
	},
	methods: {
		getData(key, value) {
			if(isNaN(value) || (this.from && this.from === 'opportunityDetail' && (key?.[0] === 'Adjusted Market' || key?.[0] === 'Early Adopter Size'))) return value 
			else return numeral(value).format('0,0.0')
		}
	}
}
</script>

