<script setup>
import {EdgeLabelRenderer, getSmoothStepPath } from '@vue-flow/core'
import { computed } from 'vue'

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  sourceX: {
    type: Number,
    required: true,
  },
  sourceY: {
    type: Number,
    required: true,
  },
  targetX: {
    type: Number,
    required: true,
  },
  targetY: {
    type: Number,
    required: true,
  },
  sourcePosition: {
    type: String,
    required: true,
  },
  targetPosition: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: false,
  },
  markerEnd: {
    type: String,
    required: false,
  },
  style: {
    type: Object,
    required: false,
  },
})


const onClick = (evt, id) => {
  evt.stopPropagation()
}

const edgePath = computed(() =>
  getSmoothStepPath({
    sourceX: props.sourceX,
    sourceY: props.sourceY,
    sourcePosition: props.sourcePosition,
    targetX: props.targetX,
    targetY: props.targetY,
    targetPosition: props.targetPosition,
  }),
)
</script>

<script>
export default {
  inheritAttrs: false,
}
</script>

<template>
  <path :id="id" :style="style" class="vue-flow__edge-path" :d="edgePath[0]" :marker-end="markerEnd" />
    <EdgeLabelRenderer>
    <div
      :style="{
        pointerEvents: 'all',
        position: 'absolute',
        transform: `translate(-50%, -50%) translate(${edgePath[1]}px,${edgePath[2]}px)`,
      }"
      class="nodrag nopan"
    >
      <div class ="flex flex-col p-1 bg-gray_bg" v-if="data && data.label">
          <div class="text-base text-center font-bold pt-8">{{data.label}}</div>
          <div class="text-sm text-center font-medium italic pt-4" v-if="data.subLabel">{{data.subLabel}}</div>
      </div>
    </div>
  </EdgeLabelRenderer>
  
</template>
