<template>
<div class="flex flex-col space-y-6">
  <div class="flex flex-col p-6 shadow-sm rounded-lg w-full items-start bg-white mt-6 md:mx-auto md:w-3/5 md:p-12">
    <div class="flex space-x-4 justify-start pb-3">
      <Icon name="ThumbUpIcon" size="10" color="lightblue_icon" is-outline></Icon>
      <Icon name="HeartIcon" size="10" color="red_icon" is-outline></Icon>
    </div>
    <Text :content="title" size="3xl" color="graytext" weight="bold" align="left" custom-class="leading-snug pb-3"></Text>
    <Text :content="subtitle" size="base" color="graytext" weight="medium" align="left"></Text>
    <Text :content="description" size="base" color="graytext" weight="normal" align="left" class="pt-12"></Text>
    <div class="pt-2 w-full">
     <InputWithCopy v-model:value="link" disabled></InputWithCopy>
    </div>
  </div>
  <div class="flex shadow-sm rounded-lg w-full items-start bg-white  md:mx-auto md:w-3/5 md:h-60">
    <img src="https://dscvry-live-media.s3.amazonaws.com/success-img.png" class="w-2/5 h-full hidden md:block" />
    <div class="flex flex-col p-6 justify-center items-start w-full md:w-3/5 md:h-full">
      <Text :content="subSectionText" size="xl" color="graytext" weight="bold" align="left" custom-class="leading-snug pb-3"></Text>
      <div class="mt-6">
          <Button :content="buttonLabel" size="md" variant="default" custom-class="bg-dark_indigo text-white justify-start" :click="buttonClick"></Button>
       </div>
    </div>
    
  </div>
</div>

</template>
<script>
import Text from '../../atoms/Text/Text.vue';
import Icon from '../../atoms/Icons/Icons.vue'
import Button from '../../atoms/Button/Button.vue'
import Image from '../../atoms/Image/Image.vue';
import InputWithCopy from '../../molecules/Inputs/InputWithCopyIcon/InputWithCopyIcon.vue'
export default {
  components: {
    Text,
    Icon,
    Button,
    Image,
    InputWithCopy
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
    subSectionText: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    linkText: {
      type: String,
      default: ""
    },
    buttonLabel: {
      type: String,
      default: "Get Started"
    },
    buttonClick: {
      type: Function,
      default: () => {}
    }

  },
  watch: {
    linkText: function (val) {
      console.log(val);
      this.link = val;
    }
  },
  data() {
    return {
      link: this.linkText
    }
  }
}
</script>