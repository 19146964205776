<template>
	<div class="w-full bg-white absolute top-0 z-10 border-b border-gray-200">
		<div class="py-6 px-4 overflow-hidden flex justify-start items-center sm:px-6 lg:px-8 md:justify-between">
        <ButtonWithIcon
            variant="default"
            :icon-name="btnIcon"
            icon-size="5"
            text-color="dark_indigo"
            size="md"
            :label="btnLabel"
            leading
            is-outline
            :click="btnClick"
            is-show-text
            custom-class="bg-white shadow-none"
          />
        <div class="text-base hidden md:block" v-html="title"></div>
		</div>
	</div>
</template>

<script>
import ButtonWithIcon from "../../../molecules/Button/WithIcon/WithIcon.vue"
export default {
  name: "WithButtonAndLabel",
  components: {
    ButtonWithIcon
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    btnLabel: {
      type: String,
      default: ''
    },
    btnIcon: {
      type: String,
      default: ''
    },
    btnClick: {
      type: Function,
      default : () => {}
    }
  }
}
</script>
