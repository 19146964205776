<template>
  <div class="flex flex-col p-6 shadow-sm rounded-lg w-full items-start bg-white mt-6 md:mx-auto md:w-4/5 md:p-12">
    <div class="flex space-x-6 justify-start pb-3">
      <Icon name="LocationMarkerIcon" size="10" color="lightblue_icon" is-outline></Icon>
      <Icon name="MapIcon" size="10" color="indigo-600" is-outline></Icon>
    </div>
    <Text :content="title" size="3xl" color="graytext" weight="bold" align="left" custom-class="leading-snug pb-3"></Text>
    <Text :content="description" size="base" color="graytext" weight="medium" align="left"></Text>
    <div class="mt-12">
      <Button :content="buttonLabel" size="md" variant="default" custom-class="bg-dark_indigo text-white justify-start" :click="buttonClick"></Button>
    </div>
  </div>
</template>
<script>
import Text from '../../atoms/Text/Text.vue';
import Icon from '../../atoms/Icons/Icons.vue'
import Button from '../../atoms/Button/Button.vue'
export default {
  components: {
    Text,
    Icon,
    Button
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    buttonLabel: {
      type: String,
      default: "Get Started"
    },
    buttonClick: {
      type: Function,
      default: () => {}
    }

  }
}
</script>