<template>
  <OpportunityDetail :badgeText="badgeText" :title="title" :demographics="demographics" 
  :navigationGraph="navigationGraph" :isShow="showDialog" :dialogTitle="dialogTitle" 
  :label="label" :tables="tables" @onClose="closeDialog"></OpportunityDetail>
  <VueFlow :nodes="nodesData" :edges="edgesData" class="basicflow bg-gray_bg z-0" :default-zoom="1.5" :min-zoom="0.2" :max-zoom="4" fit-view-on-init>
    <template #node-gray="props">
      <CustomNode bg-color="gray-200" border-color="gray-500" :nodeClicked="() => onClickNode(props.id)" :label="props.label" :count="props.data && props.data.count" :is-active="props.data && props.data.active"></CustomNode>
    </template>
    <template #node-green="props">
      <CustomNode bg-color="green-100" border-color="green-700" :nodeClicked="() => onClickNode(props.id)"  :label="props.label" :count="props.data && props.data.count" :is-active="props.data && props.data.active"></CustomNode>
    </template>
    <template #node-blue="props">
      <CustomNode bg-color="blue-100" border-color="blue-700" :nodeClicked="() => onClickNode(props.id)"  :label="props.label" :count="props.data && props.data.count" :is-active="props.data && props.data.active"></CustomNode>
    </template>
    <template #edge-custom="props">
      <CustomEdge v-bind="props" />
    </template>
    <MiniMap v-if="windowWidth > 500" />
    <Controls />
    <div class= "absolute m-auto left-0 right-0 bottom-4">
      <div class="flex justify-center items-center mx-auto space-x-3">
        <div v-for="item in legend" :key="item.id" class="flex space-x-2"> 
          <Text :content="item.label" size="sm" weight="medium" color="gray-900"></Text>
          <div class="h-5 w-5 border rounded-full" :class="getLegendClass(item.type)"></div>
        </div>
      </div>
    </div>
  </VueFlow> 
</template>
<script>
import { Background, Controls, MiniMap } from '@vue-flow/additional-components'
import {  VueFlow,  useVueFlow, getConnectedEdges, MarkerType } from '@vue-flow/core'
import { ref, watch, onMounted } from 'vue'
import Text from '../../atoms/Text/Text.vue';
import CustomEdge from './CustomEdge.vue'
import CustomNode from './CustomNode.vue';
import OpportunityDetail from '../Modals/OpportunityDetailSlideover/OpportunityDetailSlideover.vue'

export default {
  components: {
    VueFlow,
    Background,
    Controls,
    MiniMap,
    Text,
    CustomEdge,
    CustomNode,
    OpportunityDetail
  },
  props: {
    nodes: {
      type: Array,
      default: () => []
    },
    links: {
      type: Array,
      default: () => []
    },
    nodeMapping: {
      type: Object,
      default: () => {}
    },
    nodeClicked: {
      type: Function,
      default: () => {}
    },
    edgeClicked: {
      type: Function,
      default: () => {}
    },
    legend: {
      type: Array,
      default: () => []
    },
    //opportunity detail slideover props
    dialogTitle: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    badgeText: {
      type: String,
      default: null,
    },
    //opportunity detail props
    navigationGraph: {
      type: Object,
      default: () => {},
    },
    demographics: {
      type: Array,
      default: () => [],
    },
    tables: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const windowWidth = ref(window.innerWidth);
    const showDialog = ref(false);
    let isNodeSelected = ref(false);
    const { toObject, onEdgeClick,onEdgeMouseEnter, onEdgeMouseLeave, getSelectedNodes, getEdges,fitView, findNode, onNodeMouseEnter, onNodeMouseLeave} = useVueFlow();
    let nodesData = ref([]);
    let edgesData = ref([]);
    let opportunityLinkMap = {};
    let nodesMap = {};
    let detachedNodes = new Map();
    let y = 100, x=100;
    const nodeShades = {blue: '#1d4ed8', green: '#15803d', gray: '#111827'};
    props.links.forEach(el => {
      if (Object.keys(opportunityLinkMap).length === 0) {
        opportunityLinkMap[el.parentId] = {
          pos: {x,y: y + 200},
          [el.childId]: {x,y: y + 200}
        };
        nodesMap[el.parentId] = {x:250, y};
        nodesMap[el.childId] = {x, y:  y + 200};
      } else {
        if (opportunityLinkMap[el.parentId]) {
          let pos = {y: opportunityLinkMap[el.parentId].pos.y, x: opportunityLinkMap[el.parentId].pos.x + 300};
          nodesMap[el.childId] = pos;
          opportunityLinkMap[el.parentId].pos = pos;
          opportunityLinkMap[el.parentId][el.childId] = pos
        } else if (nodesMap[el.parentId]) {
            if (!nodesMap[el.childId]) {
              opportunityLinkMap[el.parentId] = {
                pos: {x,y: nodesMap[el.parentId].y + 200},
                [el.childId]: {x,y: nodesMap[el.parentId].y + 200}
              };
              nodesMap[el.childId] = {x,y: nodesMap[el.parentId].y + 200};
            } else {
              opportunityLinkMap[el.parentId] = {
                pos: nodesMap[el.childId],
                [el.childId]: nodesMap[el.childId]
              };
            }
        } else {
          let parentItems = Object.keys(opportunityLinkMap);
          let parentPos = {x, y: ((parentItems.length * 2) *100) + 200}
          opportunityLinkMap[el.parentId] = {
            pos: {x,y: parentPos.y + 200},
            [el.childId]: {x,y: parentPos.y + 200}
          };
          nodesMap[el.parentId]= parentPos;
          nodesMap[el.childId]= {x,y: parentPos.y + 200};
        }
      } 
      edgesData.value.push({
          ...el,
          source: el.parentId,
          target: el.childId,
          style: {strokeWidth: '6px', stroke: '#d1d5db'},
          type: el.type  || 'smoothstep',
      });
    })
    nodesData = props.nodes.map((el) => {
      if (!nodesMap[el.id]) {
        let pos = detachedNodes.size > 0 ? {x: detachedNodes.get('pos').x + 300, y: 20} : {x: 100, y: 20};
        detachedNodes.set('pos', pos);
        detachedNodes.set(el.id, pos);
      }
      return {
        id: el.id,
        label: el.name,
        type: props.nodeMapping[el.type],
        position: nodesMap[el.id] ? nodesMap[el.id] : detachedNodes.get(el.id),
        data: {...el}
      }
    });
    const getLegendClass = (type) => {
      if (props.nodeMapping[type] === 'blue') {
        return 'border-blue-700 bg-blue-100';

      } else if (props.nodeMapping[type] === 'green') {
         return 'border-green-700 bg-green-100';
      } else {
         return 'border-gray-700 bg-gray-100';
      }
    }
    onEdgeClick((event) => {
      props.edgeClicked(event.edge);
    })
    onEdgeMouseEnter((event) => {
      getEdges.value.forEach((item) => {
        if (item.parentId === event.edge.parentId) {
          item.style = {...(item.style||{}), stroke: 'transparent'};
        }
      });
      event.edge.style = { ...(event.edge.style || {}), stroke: '#1f2937' }
    })
    onEdgeMouseLeave((event) => {
       getEdges.value.forEach((item) => {
        if (item.parentId === event.edge.parentId) {
          item.style = {...(item.style||{}), stroke: '#d1d5db'};
        }
      })
      event.edge.style = { ...(event.edge.style || {}), stroke: '#d1d5db' }
    })

    onNodeMouseEnter((event) => {
      if (!isNodeSelected.value) {
        highlightConnectedEdges(event.node.id,event.connectedEdges);
      }
 
    });
    onNodeMouseLeave((event) => {
      if (!isNodeSelected.value) {
          let connectedParentEdges = getConnectedParentEdges(event.node.id,event.connectedEdges);
          clearNodesHiglighting(event.connectedEdges, connectedParentEdges);
      }
    })

  /**
     * toObject transforms your current graph data to an easily persist-able object
     */
    const logToObject = () => console.log(toObject());

    const getConnectedParentEdges = (nodeId,connectedEdges) => {
      let parentId = null;
      connectedEdges.forEach(edge => {
        if (nodeId === edge.target) {
          parentId = edge.source;
        }
      });
      const parentNode = findNode(parentId);
      let connectedParentEdges = [];
      if (parentNode) {
        connectedParentEdges = getConnectedEdges([parentNode], getEdges.value);
      }
      return connectedParentEdges
    }

    const highlightConnectedEdges = (nodeId, connectedEdges) => {
       let parentId = null;
      connectedEdges.forEach(edge => {
        let edgeStroke = nodeId === edge.source ? edge.targetNode.type : edge.sourceNode.type;
           edge.style = {
            ...(edge.style || {}),
            stroke: nodeShades[edgeStroke]
          }
        if (nodeId === edge.target) {
          parentId = edge.source;
        }
      });
      const parentNode = findNode(parentId);
      let connectedParentEdges = [];
      if (parentNode) {
        connectedParentEdges = getConnectedEdges([parentNode], getEdges.value);
        connectedParentEdges.forEach(edge => {
          if (edge.target !== nodeId) {
             edge.style = {
            ...(edge.style || {}),
            stroke: 'transparent'
          }
          }
        });
      } 
      return {connectedParentEdges};
    }
    const clearNodesHiglighting = (connectedEdges,connectedParentEdges) => {
        connectedEdges.forEach(edge => {
          edge.style = {...(edge.style || {}), strokeWidth: '6px', stroke: '#d1d5db'}
        });
        connectedParentEdges.forEach(edge => {
          edge.style = {...(edge.style || {}), strokeWidth: '6px', stroke: '#d1d5db'}
        });
    }

    watch(getSelectedNodes, (nodes, _, cleanup) => {
     
      let selectedNodeId = null;
      if (nodes.length > 0) {
         isNodeSelected.value = true;
        selectedNodeId = nodes[0].id;
        if (nodes[0].data) {
          nodes[0].data['active'] = true;
        } else {
          nodes[0].data = {
            active: true
          };
        }
      } 

      const connectedEdges = getConnectedEdges(nodes, getEdges.value);
      const { connectedParentEdges} = highlightConnectedEdges(selectedNodeId,connectedEdges);
      cleanup(() => {
        isNodeSelected.value = false;
        clearNodesHiglighting(connectedEdges,connectedParentEdges);
        if(selectedNodeId) {
          nodes[0].data.active = false;
        } 
      })
    })
    const handleResize = () => {
      windowWidth.value = window.innerWidth;
      fitView();
    }

    const onClickNode = (nodeId) => {
      showDialog.value = true;
      props.nodeClicked(nodeId);
    }
    const closeDialog = () => {
      showDialog.value = false;
    }

    onMounted(() => {
      window.addEventListener('resize', handleResize)
    })

    return {
      logToObject,
      edgesData,
      nodesData,
      getLegendClass,
      windowWidth,
      showDialog,
      closeDialog,
      onClickNode
    };   
  },
};
</script>
<style scoped>
  @import './main.css';
</style>
