<template>
  <div class="border p-3 w-48 flex items-center justify-center rounded-md drop-shadow-xl shadow-lg hover:border-2"
    :class="`border-${isActive ? 'gray-900' :borderColor} bg-${isActive? 'white' : bgColor}`" @dblclick="nodeClicked">
    <div v-if="count" class="p-2 rounded-full bg-black flex items-center justify-center mr-1.5">
      <Text :content="count" weight="semibold" size="xs" color="white" align="center"></Text>
    </div>
    <Text :content="label" weight="semibold" size="sm" color="gray-900" align="center"></Text>
  </div>
</template>
<script>
import Text from '../../atoms/Text/Text.vue'
export default {
  components: {
    Text
  },
  props: {
    bgColor: {
      type: String,
      default: 'white'
    },
    borderColor: {
      type: String,
      default: 'gray-700'
    },
    label: {
      type: String,
      default: null
    },
    count: {
      type: String,
      default: null
    },
    nodeClicked: {
      type: Function,
      default: () => {}
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }

}
</script>