<template>
  <div class="overflow-y-scroll h-screen bg-gray_bg pt-16">
    <Header></Header>
    <div class="flex flex-col p-3 pb-16">
      <GreetingView 
        v-if="showGreetingTemplate"
        :title="gvTitle" 
        :description="gvDescription" 
        :button-click="gvButtonClicked" 
        :button-label="gvButtonLabel"
        class="pb-16"
      ></GreetingView>
      <div v-else-if="showSurveyForm" class="flex flex-col">
        <template  v-for="(item,index) in formData" :key="item.id">
          <div v-show="index === selectedStep" class="p-6 shadow-sm rounded-lg w-full items-center bg-white mt-6 mb-10 md:mx-auto md:w-4/5 md:px-12 md:py-6 md:mb-16">
            <SurveyForm
              :ref="(el) => assignRef(el, index)"
              :list="item.list"
              :title="item.name"
              class="py-6 w-full"
              :modelKey="modelKey"
              :orig-form-data="savedFormData"
              @on-validate="onValidate"
            />
          </div>
        </template>

      </div>
      <SuccessView 
        v-else-if="showSuccessTemplate"
        :title="svTitle" 
        :description="svDescription"
        :subtitle="svSubtitle"
        :sub-section-text="svSubSectionText"
        :link-text="svLinkText"
        :button-label="svButtonLabel"
        :button-click="svButtonClick"
      ></SuccessView>
    </div>
    <div v-if="showSurveyForm" 
        class="absolute bottom-0 w-full flex justify-between items-center border-t p-3 rounded-b-lg items-center bg-white md:p-6">
        <div class="flex">
          <div class="h-11 w-11 rounded-full bg-white text-sm font-semibold text-gray-900 border-2 items-center flex justify-center">{{selectedStep + 1}}/{{stepsData.length}}</div>
          <ButtonWithIcon
            v-if="selectedStep > 0"
            variant="default"
            icon-name="ArrowSmLeftIcon"
            icon-size="5"
            text-color="dark_indigo"
            size="md"
            label="Back"
            leading
            is-outline
            :click="backClicked"
            is-show-text
            custom-class="bg-white shadow-none"
          />
        </div>
        <div>
          <ButtonWithIcon
            v-if="selectedStep < stepsData.length - 1"
            variant="default"
            icon-name="ArrowSmRightIcon"
            icon-size="5"
            text-color="white"
            size="md"
            label="Next"
            is-outline
            is-show-text
            :click="nextClicked"
            :disabled="!isValid"
            custom-class="bg-dark_indigo"
          />
          <ButtonWithIcon
            v-else
            variant="default"
            icon-name="ThumbUpIcon"
            icon-size="5"
            text-color="white"
            size="md"
            label="Finish"
            is-outline-icon
            is-show-text
            :click="submitForm"
            :disabled="!isValid"
            custom-class="bg-dark_indigo"
          />
        </div>
    </div>
    <Footer v-if="showSuccessTemplate || showGreetingTemplate"></Footer>
  </div>
</template>

<script>
import ButtonWithIcon from "../../molecules/Button/WithIcon/WithIcon.vue"
import { ref } from "vue";
import SurveyForm from "../../organisms/SurveyForm/SurveyForm.vue";
import Header from "../../organisms/Headers/SimpleWithLogo/SimpleWithLogo.vue"
import Footer from "../../organisms/Footer/SimpleCentered/SimpleCentered.vue"
import SuccessView from "../../organisms/SurveySuccessView/SurveySuccessView.vue"
import GreetingView from "../../organisms/SurveyGreetingView/SurveyGreetingView.vue"
export default {
  components: {
    SurveyForm,
    Header,
    Footer,
    ButtonWithIcon,
    SuccessView,
    GreetingView
  },
  props: {
    stepsData: {
      type: Object,
      default: () => {},
    },
    submit: {
      type: Function,
      default: () => {},
    },
    next: {
      type: Function,
      default: () => {},
    },
    previous: {
      type: Function,
      default: () => {},
    },
		modelKey: {
			type: String,
			default: 'id'
		},
    // greeting template props
    showGreetingView: {
      type: Boolean,
      default: false
    },
    gvTitle: {
      type: String,
      default: ""
    },
    gvDescription: {
      type: String,
      default: ""
    },
    gvButtonLabel: {
      type: String,
      default: "Get Started"
    },
    gvButtonClick: {
      type: Function,
      default: () => {}
    },
    // success template props
    showSuccessView: {
      type: Boolean,
      default: false
    },
    svTitle: {
      type: String,
      default: ""
    },
    svSubtitle: {
      type: String,
      default: ""
    },
    svSubSectionText: {
      type: String,
      default: ""
    },
    svDescription: {
      type: String,
      default: ""
    },
    svLinkText: {
      type: String,
      default: ""
    },
    svButtonLabel: {
      type: String,
      default: "Get Started"
    },
    svButtonClick: {
      type: Function,
      default: () => {}
    }

  },
  setup(props) {
    const formRefs = ref([]);
    let savedFormData = ref({});
    const formData = ref(props.stepsData);
    const showSuccessTemplate = ref(false);
    const isValid = ref(false);
    const showGreetingTemplate = ref(props.showGreetingView);
    const showSurveyForm = ref(!props.showGreetingView);
    let surveyAnswersMap = {};
    let selectedStep = ref(0);
    const submitForm = () => {
      surveyAnswersMap = formRefs.value[selectedStep.value].getFormObject();
      props.submit(surveyAnswersMap);
      if (props.showSuccessView) {
        showSurveyForm.value = false;
        showSuccessTemplate.value = true;
      }
    };
  
    const nextClicked = () => {
      surveyAnswersMap = formRefs.value[selectedStep.value].getFormObject();
      savedFormData = {...savedFormData, ...surveyAnswersMap};
      props.next(surveyAnswersMap);
      selectedStep.value = selectedStep.value + 1;
      let valid = formRefs.value[selectedStep.value].checkValidForm()
			onValidate(valid);
    }
    const backClicked = () => {
      surveyAnswersMap = formRefs.value[selectedStep.value].getFormObject();
      savedFormData = {...savedFormData, ...surveyAnswersMap};
      props.previous(surveyAnswersMap);
      selectedStep.value = selectedStep.value - 1;
      let valid = formRefs.value[selectedStep.value].checkValidForm()
			onValidate(valid);
    };

    const gvButtonClicked = () => {
      showGreetingTemplate.value = false;
      showSurveyForm.value = true;
      if (props.gvButtonClick) {
        props.gvButtonClick();
      }
    };
    const assignRef=(el, index) => {
      if (index >= formRefs.value.length) {
        formRefs.value.push(el);
      } else {
        formRefs.value[index] = el;
      }
      return formRefs.value[index];
    }

    const onValidate = (val) => {
			isValid.value = !val;
    }

    return {
      formRefs,
      submitForm,
      nextClicked,
      backClicked,
      selectedStep,
      formData,
      showSuccessTemplate,
      showGreetingTemplate,
      showSurveyForm,
      gvButtonClicked,
      onValidate,
      isValid,
      assignRef,
      savedFormData
    };
  },
};
</script>

