<template>
  <div class="overflow-y-scroll h-screen bg-gray_bg pt-16">
    <Header :title="title" :btnClick="btnClick" :btnIcon="btnIcon" :btnLabel="btnLabel"></Header>
    <OpportunityMap :nodes="nodes" :links="links" :legend="legend" :edgeClicked="edgeClicked" :nodeMapping="nodeMapping" :nodeClicked="nodeClicked"
    :badgeText="badgeText" :title="detailDialogTitle" :demographics="demographics" :navigationGraph="navigationGraph"  :dialogTitle="dialogTitle" 
    :label="label" :tables="tables"></OpportunityMap>
  </div>
</template>

<script>
import { ref } from "vue";
import OpportunityMap from "../../organisms/OpportunityMap/OpportunityMap.vue";
import Header from "../../organisms/Headers/WithButtonAndLabel/WithButtonAndLabel.vue"
export default {
  components: {
    OpportunityMap,
    Header,
  },
  props: {
   nodes: {
      type: Array,
      default: () => []
    },
    links: {
      type: Array,
      default: () => []
    },
    nodeMapping: {
      type: Object,
      default: () => {}
    },
    nodeClicked: {
      type: Function,
      default: () => {}
    },
    edgeClicked: {
      type: Function,
      default: () => {}
    },
    legend: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    btnLabel: {
      type: String,
      default: ''
    },
    btnIcon: {
      type: String,
      default: ''
    },
    btnClick: {
      type: Function,
      default : () => {}
    },
     dialogTitle: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    badgeText: {
      type: String,
      default: null,
    },
    //opportunity detail props
    navigationGraph: {
      type: Object,
      default: () => {},
    },
    demographics: {
      type: Array,
      default: () => [],
    },
    tables: {
      type: Array,
      default: () => [],
    },
    detailDialogtitle: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    
  },
};
</script>

